@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 100 700;
  src:
    url("fonts/JosefinSans-Variable.woff2") format("woff2 supports variations"),
    url("fonts/JosefinSans-Variable.woff2") format("woff2-variations");
}

@font-face {
  font-family: "Josefin Sans";
  font-style: italic;
  font-weight: 100 700;
  src:
    url("fonts/JosefinSans-Italic-Variable.woff2") format("woff2 supports variations"),
    url("fonts/JosefinSans-Italic-Variable.woff2") format("woff2-variations");
}

@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400 800;
  src:
    url("fonts/EBGaramond-Variable.woff2") format("woff2 supports variations"),
    url("fonts/EBGaramond-Variable.woff2") format("woff2-variations");
}

@font-face {
  font-family: "EB Garamond";
  font-style: italic;
  font-weight: 400 800;
  src:
    url("fonts/EBGaramond-Italic-Variable.woff2") format("woff2 supports variations"),
    url("fonts/EBGaramond-Italic-Variable.woff2") format("woff2-variations");
}
